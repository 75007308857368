import Vue from "vue";
import Vuex from "vuex";

import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import common from "./common.module";
import exam from "./exam.module";
import resTab from "./resTab.module";
import auth from "./auth.module";
import sheet from "./sheet.module";
import getters from "./getters";
import mark from "./mark.module";
import scan from "./scan.module";
import report from "./report.module";
import createSheet from "./createSheet.module";
import homework from "./homework.module";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    common,
    resTab,
    exam,
    sheet,
    mark,
    scan,
    report,
    createSheet,
    homework,
  },
  state: {},
  mutations: {},
  actions: {},
  getters,
});
