import Vue from "vue";
import { Loading } from "element-ui";

const chartLoading = {
  install: function () {
    Vue.directive("lazyChart", {
      bind: function (el, binding) {
        const options = {
          target: el,
        };
        const io = new IntersectionObserver(
          (entries) => {
            entries.forEach((item) => {
              if (item.isIntersecting) {
                let loadingInstance = Loading.service(options);
                binding.value.fn(loadingInstance, io);
              }
            });
          },
          { threshold: 0.2 }
        );
        io.observe(el);
      },
    });
  },
};

export default chartLoading;
