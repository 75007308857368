import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { setStore, getStore, clearLogonStore } from "@/core/util/store";
import store from "@/core/services/store";
// import { extendException } from "@/core/api/login";
import { useRouter } from "./useRouter";
import { unionSheetRouter } from "@/router/modules/union";
import { isExpiredForPath } from "@/core/util/util";
// import { MessageBox } from "element-ui";
import { dashboardRouter } from "@/core/util/constdata";
const routerModules = useRouter();
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    // meta: {
    //   keepAlive: true
    // },
    children: [
      ...routerModules,
      //组卷全屏页面
      {
        path: "/paperPreview",
        name: "PaperPreview",
        meta: {
          fullScreen: true,
        },
        component: () => import("@/views/resource/paperPreview/index.vue"),
      },

      // 错误页面
      {
        path: "/error",
        name: "error",
        meta: {
          viewAll: true,
          routeWhitelist: true,
        },
        component: () => import("@/views/error.vue"),
      },

      // 个人信息
      {
        path: "/userinfo",
        name: "userinfo",
        meta: {
          viewAll: true,
          newViews: true,
        },
        component: () => import("@/views/UserInfo.vue"),
      },
      {
        path: "/res/xuekewang",
        name: "xuekewang",
        meta: {
          name: "/res/resource",
          iframe: true,
        },
        component: () => import("@/views/xuekewang/xuekewang.vue"),
      },
      // 智能排课
      {
        path: "/AICourse/AICourse",
        name: "AICourse",
        meta: {
          viewAll: true,
          newViews: true,
          name: "/AICourse/AICourse",
        },
        component: () => import("@/views/AICourse/AICourse.vue"),
      },
      {
        path: "/subjectiveQuestions",
        name: "subjectiveQuestions",
        meta: {
          name: "/subjectiveQuestions",
        },
        component: () => import("../views/About.vue"),
      },
      {
        path: "/academicAnalysis",
        name: "academicAnalysis",
        meta: {
          name: "/academicAnalysis",
        },
        component: () => import("../views/About.vue"),
      },
      //在线辅导
      {
        path: "/tutoring",
        name: "Tutoring",
        meta: {
          name: "/tutoring",
        },
        component: () => import("@/views/tutoring/index.vue"),
      },

      {
        path: "/onlineTutoring",
        name: "onlineTutoring",
        meta: {
          name: "/onlineTutoring",
        },
        component: () => import("../views/About.vue"),
      },
    ],
  },
  {
    path: "/union/sheet",
    name: "union-sheet",
    meta: {
      fullScreen: true,
    },
    redirect: "/union/welcome",
    component: () => import("@/views/union/academic/sheet/index.vue"),
    children: [...unionSheetRouter],
  },
  {
    path: "/academic/union/params",
    name: "/academic/union/params",
    meta: {
      newViews: true,
      tagName: "academic",
      title: "发布成绩",
    },
    //发布成绩
    component: () => import("@/views/union/publish/params"),
  },
  {
    path: "/union/Ndetail",
    name: "union-sheet-Ndetail",
    meta: {
      fullScreen: true,
    },
    component: () =>
      import("@/views/union/academic/sheet/comparison/Ndetail.vue"),
  },
  // 模板调整区域
  {
    path: "/templateAdjustArea",
    name: "TemplateAdjustArea",
    meta: {
      name: "/exam/exam",
      title: "调整区域",
      newViews: true,
      viewAll: true,
    },
    component: () => import("@/views/exam/template/templateAdjustArea.vue"),
  },

  {
    path: "/retrievePassword",
    name: "RetrievePassword",
    meta: {
      name: "RetrievePassword",
      viewAll: true,
      newViews: true,
      routeWhitelist: true,
    },
    component: () => import("../views/retrievePassword.vue"),
  },
  {
    path: "/mark/mark-question",
    name: "mark-question",
    meta: {
      tagName: "mark",
      name: "/mark/mark-question",
      newViews: true,
      title: "批阅",
      viewAll: true,
    },
    // 批阅 打分全屏页面
    component: () => import("@/views/mark/mark"),
  },
  {
    path: "/academic/once/muchTableDetail",
    name: "/academic/once/muchTableDetail",
    meta: {
      newViews: true,
      tagName: "academic",
      viewAll: true,
      name: "/academic/once",
      title: "一次考试统计详情",
    },
    // 一次考试统计详情全屏页面
    component: () => import("@/views/academic/once/muchTableDetail.vue"),
  },
  {
    path: "/mark/tip",
    name: "/mark/tip",
    meta: {
      newViews: true,
      tagName: "mark",
      viewAll: true,
      name: "/mark/tip",
      title: "阅卷提示",
    },
    // 阅卷提示
    component: () => import("@/views/mark/tip.vue"),
  },
  {
    path: "/academic/once/detail",
    name: "/academic/once/detail",
    meta: {
      newViews: true,
      viewAll: true,
      tagName: "academic",
      tabName: "academic",
      name: "/academic/ooce",
      title: "一次考试统计详情",
    },
    //一次考试统计详情全屏页面
    component: () => import("@/views/academic/once/detail"),
  },
  {
    path: "/mark/mark-answer",
    name: "mark-answer",
    meta: {
      tagName: "mark",
      name: "/mark/mark-answer",
      newViews: true,
      title: "查看标准答案",
      viewAll: true,
    },
    // 批阅 查看标准答案
    component: () => import("@/views/mark/answer"),
  },
  {
    path: "/mark/new-mark-question",
    name: "new-mark-question",
    meta: {
      tagName: "mark",
      name: "/mark/new-mark-question",
      newViews: true,
      title: "批阅",
      viewAll: true,
    },
    // 批阅 打分全屏页面
    component: () => import("@/views/newMark/new-mark"),
  },
  {
    path: "/mark/mark-typicalDetail",
    name: "mark-typicalDetail",
    meta: {
      tagName: "mark",
      name: "/mark/mark-typical-detail",
      newViews: true,
      viewAll: true,
      title: "典型题详情",
    },
    // 典型题详情
    component: () => import("@/views/mark/typicalDetail"),
  },
  {
    path: "/mark/choose",
    name: "mark-choose",
    meta: {
      tagName: "mark",
      name: "/mark/mark-choose",
      newViews: true,
      viewAll: true,
      title: "选做题处理",
    },
    // 选做题处理
    component: () => import("@/views/mark/choose"),
  },

  {
    path: "/analyIndex",
    name: "analyIndex",
    component: () => import("@/views/analy/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      viewAll: true,
    },
    component: () => import("@/views/login.vue"),
  },
  {
    // 跳转
    path: "/redirect",
    name: "redirect",
    component: () => import("@/views/Redirect.vue"),
  },
  {
    // 助教账号列表
    path: "/schoolList",
    name: "schoolList",
    meta: {
      viewAll: true,
      newViews: true,
    },
    component: () => import("@/views/schoolList/index.vue"),
  },

  {
    // the 404 route, when none of the above matches
    path: "/downFile",
    name: "downFile",
    meta: {
      viewAll: true,
      newViews: true,
      routeWhitelist: true,
    },
    component: () => import("@/views/downFile.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/exportFileList",
    name: "exportFileList",
    meta: {
      routeWhitelist: true,
    },
    component: () => import("@/views/exportFileList.vue"),
  },

  //三方答题卡全屏页面 start
  // creartThirdpartySheet
  {
    path: "/creartThirdpartySheet",
    name: "CreartThirdpartySheet",
    meta: {
      viewAll: true,
    },
    component: () => import("@/views/sheet/creartThirdpartySheet.vue"),
  },
  {
    path: "/thirdPartyAnswerSheet",
    name: "ThirdPartyAnswerSheet",
    meta: {
      viewAll: true,
    },
    component: () =>
      import("@/views/sheet/thirdPartyAnswerSheet/thirdPartyAnswerSheet.vue"),
  },
  // 新建答题卡全屏页面
  {
    path: "/sheetEdit",
    name: "SheetEdit",
    meta: {
      viewAll: true,
    },
    component: () => import("@/views/sheet/sheetEdit.vue"),
  },
  // 新建答题卡全屏页面 非考试
  {
    path: "/createThirdSheet",
    name: "CreateThirdSheet",
    meta: {
      viewAll: true,
      title: "创建答题卡",
    },
    component: () =>
      import("@/views/sheet/createThirdSheet/createThirdSheet.vue"),
  },
  // 答题卡图片页面
  {
    path: "/showSheetImg",
    name: "showSheetImg",
    meta: {
      viewAll: true,
    },
    component: () => import("@/views/sheet/showSheetImg.vue"),
  },
  // 三方答题卡全屏页面 end
  // 异常处理全屏页面
  {
    path: "/scanaBnormalPaper",
    name: "ScanaBnormalPaper",
    meta: {
      viewAll: true,
    },
    component: () => import("@/views/exam/scanSheet/scanaBnormalPaper.vue"),
  },
  // 异常处理联考全屏页面
  {
    path: "/exam/scanaBnormalPaper",
    name: "unionscanaBnormalPaper",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "异常处理",
    },
    component: () =>
      import("@/views/examUnion/scanSheet/scanaBnormalPaper.vue"),
  },
  // 阅卷报警 查看试题
  // ShowQuestionImgs
  {
    path: "/ShowQuestionImgs",
    name: "ShowQuestionImgs",
    meta: {
      viewAll: true,
    },
    component: () => import("@/views/exam/paperMonitor/ShowQuestionImgs.vue"),
  },
  // 查看试卷
  {
    path: "/ShowPaperImg",
    name: "ShowPaperImg",
    meta: {
      viewAll: true,
    },
    component: () => import("@/views/exam/paperMonitor/ShowPaperImg.vue"),
  },
  // 查看学生试卷
  {
    path: "/ShowPaperAnswer",
    name: "ShowPaperAnswer",
    meta: {
      viewAll: true,
    },
    component: () => import("@/views/exam/paperMonitor/ShowPaperAnswer.vue"),
  },
  // 抽查阅卷
  {
    path: "/ShowCheckPaper",
    name: "ShowCheckPaper",
    meta: {
      viewAll: true,
    },
    component: () => import("@/views/exam/paperMonitor/ShowCheckPaper.vue"),
  },
  // 调整区域
  {
    path: "/ExamAdjustArea",
    name: "ExamAdjustArea",
    meta: {
      viewAll: true,
    },
    component: () => import("@/views/exam/ExamAdjustArea.vue"),
  },
  // 调整区域
  {
    path: "/ExamAdjustAreaAll",
    name: "ExamAdjustAreaAll",
    meta: {
      viewAll: true,
      title: "调整主观题区域",
    },
    component: () => import("@/views/exam/ExamAdjustAreaAll.vue"),
  },
  // 调整区域
  {
    path: "/ExamBindImg",
    name: "ExamBindImg",
    meta: {
      viewAll: true,
    },
    component: () => import("@/views/exam/ExamBindImg.vue"),
  },
  //学生作业页面
  {
    path: "/homework/makeSheet",
    name: "/homework/makeSheet",
    meta: {
      viewAll: true,
      newViews: true,
    },
    component: () => import("@/views/studentsHomework/makeSheet/makeSheet.vue"),
  },
  // 作业预览
  {
    path: "/homework/paperDetails",
    name: "/homework/paperDetails",
    meta: {
      viewAll: true,
      newViews: true,
    },
    component: () =>
      import("@/views/studentsHomework/paperDetails/paperDetails.vue"),
  },
  // 查看标注卡信息
  {
    path: "/showImgItem",
    name: "/showImgItem",
    meta: {
      viewAll: true,
      newViews: true,
    },
    component: () => import("@/views/showImgItem.vue"),
  },
  // 替换图片
  {
    path: "/ReplaceThePicture",
    name: "ReplaceThePicture",
    meta: {
      viewAll: true,
    },
    component: () => import("@/views/exam/scanSheet/ReplaceThePicture.vue"),
  },
  // 异常处理页面  作业
  {
    path: "/homework/handleException",
    name: "/homework/handleException",
    meta: {
      viewAll: true,
    },
    component: () =>
      import("@/views/studentsHomework/scan/handleException.vue"),
  },
  // 紧急跳转页面
  {
    path: "/EmergencyPage",
    name: "EmergencyPage",
    meta: {
      viewAll: true,
      routeWhitelist: true,
    },
    component: () => import("@/views/EmergencyPage.vue"),
  },
];

let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    //如果你成功失败的回调都传了
    originPush.call(this, location, resolve, reject);
  } else {
    //否则,成功失败回调传其一,或者都不传的情况
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};

// 重写replace
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject);
  } else {
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
const router = new VueRouter({
  routes,
});

//路由卫士  不会报错  注意
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = `文通云 | ${to.meta.title}`;
  } else {
    document.title = "文通云 | 精准教学大数据系统";
  }

  // 判断路由跳转  如果用户两小时未操作  清楚缓存
  let time = getStore({ name: "lastRequestTime" });
  let loginOnline = getStore({ name: "loginOnline" });
  let newTime = parseInt(new Date().getTime() / 1000);
  if (!loginOnline) {
    if (!time) {
      clearLogonStore();
    } else {
      if (newTime - Number(time) > 7200) {
        clearLogonStore();
      }
    }
  }

  // 特殊判断不需要登录
  if (to.meta.routeWhitelist) {
    next();
    return;
  }
  if (to.name == "redirect") {
    setStore({
      name: "userInfo",
      content: {
        id: Number(to.query.userId),
        username: to.query.username,
        schoolId: to.query.schoolId,
        authorities: [],
      },
    });
    next();
    return;
  }
  //  修复一个问题  如果用户没有选择学校  那么  清楚缓存  在管理员的情况下
  const userInfo = getStore({ name: "userInfo" });
  if (userInfo && !userInfo.schoolId && to.name != "schoolList") {
    clearLogonStore();
  }

  if (to.meta.keepAlive) {
    if (store.getters.getKeepArr.indexOf(to.name) == -1) {
      store.commit("setKeepArr", to.name);
    }
  }
  if (from.meta.keepAlive) {
    if (store.getters.getKeepArr.indexOf(from.name) == -1) {
      store.commit("setKeepArr", from.name);
    }
  }
  if (userInfo) {
    let isExpiredPath = isExpiredForPath();
    if (isExpiredPath) {
      let dashboardPaths = [
        ...dashboardRouter.map((item) => item.url),
        "/dashboard/admin",
        "/schoolList",
      ];
      if (!dashboardPaths.includes(to.path)) {
        document.title = "文通云 | 精准教学大数据系统";
        next({ path: isExpiredPath });
      } else {
        next();
      }
    }
  }
  if (to.name != "login") {
    if (getStore({ name: "userInfo" }) && getStore({ name: "userInfo" }).id) {
      if (to.name == "Home") {
        //如果是学生就跳学生学情
        let userKey = getStore({ name: "userInfo" }).authorities;
        let routerList = getStore({ name: "router-list" });
        // console.log(routerList);
        if (userKey[0].authority == "ROLE_4") {
          next({ name: "academic-reportStat" });
        } else {
          if (routerList && JSON.parse(routerList).length > 0) {
            next({ path: JSON.parse(routerList)[0].url });
          } else {
            clearLogonStore();

            next({ path: "/login" });
          }
        }
      } else {
        next();
      }
    } else {
      next({ path: "/login" });
    }
  } else {
    if (getStore({ name: "userInfo" }) && getStore({ name: "userInfo" }).id) {
      //如果是学生就跳学生学情
      let userKey = getStore({ name: "userInfo" }).authorities;
      let routerList = getStore({ name: "router-list" });

      if (userKey[0].authority == "ROLE_4") {
        next({ name: "academic-reportStat" });
      } else {
        if (routerList && JSON.parse(routerList).length > 0) {
          let routeItem = JSON.parse(routerList)[0];
          let url = routeItem.url;
          if (routeItem.childList && routeItem.childList.length) {
            url = routeItem.childList[0].url;
          }
          next({ path: url });
        } else {
          clearLogonStore();

          next({ path: "/login" });
        }
      }
    } else {
      next();
    }
  }
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  } else {
    // console.log();
    let str = "http";
    if (window.location.href.indexOf(str) != 0) {
      window.location.href = "https://wtjy.com/#/login";
      return;
    }
    // extendException(JSON.stringify(error));
    // MessageBox.alert(
    //   "亲爱的老师您好，因文通云版本更新优化，请点击 “刷新” 按钮刷新页面后重新操作页面!",
    //   "版本更新提示",
    //   {
    //     confirmButtonText: "刷新",
    //     callback: () => {
    //       window.location.reload();
    //     },
    //   }
    // );
  }
});

export default router;
