<template>
  <div class="noData">
    <!-- <img src="@/static/academic/nodata.png" :class="size" alt="" /> -->
    <img src="@/assets/newExam/noData.png" :class="size" alt="" />
    <div v-if="title" class="title">{{ title }}</div>
    <template v-else>
      <slot name="title"></slot>
    </template>
    <div v-if="message">{{ message || "暂无数据" }}</div>
    <template v-else>
      <slot name="message"></slot>
    </template>
  </div>
</template>

<script>
export default {
  name: "NoData",
  props: {
    size: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.noData {
  color: #ababab;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  img {
    margin-bottom: 20px;
  }
  .small {
    width: 80px;
    height: 80px;
  }
}
.title {
  // color: #000;
  font-weight: 500;
  font-size: 18px;
}
</style>
