<template>
  <div class="footer">
    <div class="btm-intro">
      <a target="_blank" href="https://beian.miit.gov.cn/"
        >蜀ICP备2021002998号</a
      >
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51011202000480"
      >
        <img src="@/assets/network.png" alt="" />
        川公网安备 51011202000480号
      </a>
      成都文通网络科技发展有限公司版权所有 400-607-6782
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.footer {
  // width: 100%;
  height: 40px;
  margin: 0 auto;
  // -webkit-box-shadow: inset 0 1px 0 0 #dddddd;
  // box-shadow: inset 0 1px 0 0 #dddddd;
  background-color: #ffffff;
  text-align: center;
  line-height: 40px;
  font-family: PingFangSC;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.5);
  // text-align: center;
  // color: #ffffff;
  font-size: 16px;
  // display: flex;
  .btm-intro {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      color: rgba(0, 0, 0, 0.5);
      text-decoration: none;
      display: flex;
      align-items: center;
      margin-right: 6px;
    }
  }
  img {
    width: 20px;
  }
}
</style>
