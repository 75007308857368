export const analy = [
  //精准教学---begin
  {
    path: "/analy/report",
    name: "analyReport",
    meta: {
      name: "/analy/report",
      tabName: "analy",
    },
    component: () => import("@/views/analy/report.vue"),
  },
  {
    path: "/analy/analyreport-detail",
    name: "analyStudentReport",
    meta: {
      name: "/analy/report",
      tabName: "analy",
    },
    component: () => import("@/views/analy/analyreport-detail.vue"),
  },
  {
    path: "/analy/manageList",
    name: "manageList",
    meta: {
      name: "/analy/report",
      tabName: "analy",
    },
    component: () => import("@/views/analy/manageList.vue"),
  },
  {
    path: "/analy/reportParameters",
    name: "ReportParameters",
    meta: {
      name: "/analy/report",
      tabName: "analy",
    },
    component: () => import("@/views/analy/reportParameters.vue"),
  },
  {
    path: "/analy/editReport",
    name: "EditReport",
    meta: {
      name: "/analy/report",
      tabName: "analy",
      keepAlive: true,
    },
    component: () => import("@/views/analy/editReport.vue"),
  },
  {
    path: "/analy/reportStructure",
    name: "reportStructure",
    meta: {
      name: "/analy/report",
      tabName: "analy",
    },
    component: () => import("@/views/analy/reportStructure.vue"),
  },
  {
    path: "/analy/report/gradeLearning",
    name: "GradeLearning",
    meta: {
      name: "/analy/report",
      tabName: "analy",
      keepAlive: true,
    },
    component: () => import("@/views/analy/learning/gradeLearning.vue"),
  },
  {
    path: "/analy/report/classLearning",
    name: "ClassLearning",
    meta: {
      name: "/analy/report",
      tabName: "analy",
      keepAlive: true,
    },
    component: () => import("@/views/analy/learning/classLearning.vue"),
  },
  {
    path: "/analy/report/studentLearning",
    name: "StudentLearning",
    meta: {
      name: "/analy/report",
      tabName: "analy",
      keepAlive: true,
    },
    component: () => import("@/views/analy/learning/studentLearning.vue"),
  },
  {
    path: "/analy/report/showKnowledge",
    name: "ShowKnowledge",
    meta: {
      name: "/analy/report",
      tabName: "analy",
      // keepAlive: true
    },
    component: () => import("@/views/analy/learning/showKnowledge.vue"),
  },
  {
    path: "/analy/report/weakTestPaper",
    name: "WeakTestPaper",
    meta: {
      name: "/analy/report",
      tabName: "analy",

      // keepAlive: true
    },
    component: () => import("@/views/analy/learning/weakTestPaper.vue"),
  },
  //精准教学---end

  // 错题本
  {
    path: "/newAnaly/notebookItem",
    name: "notebookItem",
    meta: {
      name: "/analy/report",
      tabName: "analy",
      newViews: true,
      title: "个性化错题本",
    },
    component: () => import("@/views/newAnaly/notebookItem/index.vue"),
  },
  {
    path: "/newAnaly/errorQuestionSetting",
    name: "errorQuestionSetting",
    meta: {
      name: "/analy/report",
      tabName: "analy",
      newViews: true,
      title: "错题规则设置",
    },
    component: () => import("@/views/newAnaly/errorQuestionSetting/index.vue"),
  },
];
