export const mark = [
  {
    path: "/mark/mark-list",
    name: "mark-list",
    meta: {
      tagName: "mark",
      name: "/mark/mark-list",
      newViews: true,
      title: "主观题阅卷",
    },
    // 主观题阅卷
    component: () => import("@/views/mark/list"),
  },
  {
    path: "/mark/mark-reappraisal",
    name: "mark-reappraisal",
    meta: {
      tagName: "mark",
      name: "/mark/mark-reappraisal",
      newViews: true,
      title: "抽查被打回试卷",
    },
    // 抽查被打回试卷
    component: () => import("@/views/mark/reappraisal"),
  },
  {
    path: "/mark/mark-review",
    name: "mark-review",
    meta: {
      tagName: "mark",
      name: "/mark/mark-review",
      newViews: true,
      title: "复查已阅",
    },
    // 复查已阅
    component: () => import("@/views/mark/review"),
  },
  {
    path: "/mark/mark-typical",
    name: "mark-typical",
    meta: {
      tagName: "mark",
      tabName: "mark",
      name: "/mark/mark-typical",
      newViews: true,
      title: "典型题",
    },
    // 典型题
    component: () => import("@/views/mark/typical"),
  },
  {
    path: "/mark/choose-list",
    name: "mark-choose-list",
    meta: {
      tagName: "mark",
      tabName: "mark",
      name: "/mark/mark-choose-list",
      newViews: true,
      title: "空白题列表",
    },
    // 空白题列表
    component: () => import("@/views/mark/choose-list"),
  },
];
