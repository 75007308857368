export const examUnion = [
  //考试管理    <----- start ----->
  {
    path: "/exam/unionExam",
    name: "/exam/unionExam",
    meta: {
      name: "/exam/exam",
      newViews: true,
      tabName: "/exam/unionExam",
      title: "考试管理",
    },
    component: () => import("@/views/examUnion/examList/index.vue"),
  },
  {
    path: "/exam/unionExamNewStudent",
    name: "unionExamNewStudent",
    meta: {
      name: "/exam/exam",
      newViews: true,
      keepAlive: true,
      title: "考生管理",
    },
    component: () => import("@/views/examUnion/ExamNewStudent/index.vue"),
  },
  {
    path: "/exam/ExamScanning",
    name: "ExamScanning",
    meta: {
      name: "/exam/exam",
      newViews: true,
      keepAlive: true,
      title: "扫描点管理",
    },
    component: () => import("@/views/examUnion/ExamScanning/index.vue"),
  },
  {
    path: "/exam/unionExamNewResult",
    name: "unionExamNewResult",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "成绩管理",
    },
    component: () =>
      import("@/views/examUnion/ExamNewResult/ExamNewResult.vue"),
  },
  {
    path: "/exam/unionExamTemplate",
    name: "unionExamTemplate",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "模板设置",
    },
    component: () => import("@/views/exam/ExamTemplate/ExamTemplate.vue"),
  },
  {
    path: "/exam/unionExamCorrectTask",
    name: "unionExamCorrectTask",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "阅卷分工",
    },
    component: () =>
      import("@/views/examUnion/ExamCorrectTask/ExamCorrectTask.vue"),
  },
  {
    path: "/exam/unionExamBindSetQuestion",
    name: "unionExamBindSetQuestion",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "阅卷分配 / 添加绑定批阅",
    },
    component: () =>
      import("@/views/examUnion/ExamBindSetQuestion/ExamBindSetQuestion.vue"),
  },
  {
    path: "/exam/unionExamBindQuestion",
    name: "unionExamBindQuestion",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "阅卷分配 / 设置绑定批阅",
    },
    component: () =>
      import("@/views/examUnion/ExamBindQuestion/ExamBindQuestion.vue"),
  },
  {
    path: "/exam/ExamQuestionLeader",
    name: "ExamQuestionLeader",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "阅卷分工 / 设置小题组长",
    },
    component: () => import("@/views/examUnion/ExamQuestionLeader/index.vue"),
  },
  {
    path: "/exam/ExamTaskGroup",
    name: "ExamTaskGroup",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "阅卷分工 / 设置阅卷分组",
    },
    component: () => import("@/views/examUnion/ExamTaskGroup/index.vue"),
  },
  {
    path: "/exam/ExamSchoolTask",
    name: "ExamSchoolTask",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "阅卷分工 / 设置校任务量",
    },
    component: () => import("@/views/examUnion/ExamSchoolTask/index.vue"),
  },
  {
    path: "/exam/ExamMarkingSmallSubject",
    name: "ExamMarkingSmallSubject",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "阅卷分工 / 阅卷小科",
    },
    component: () =>
      import("@/views/examUnion/ExamMarkingSmallSubject/index.vue"),
  },
  {
    path: "/exam/unionExamSetTaskRules",
    name: "unionExamSetTaskRules",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "阅卷分工 / 批量设置规则",
    },
    component: () =>
      import("@/views/examUnion/ExamSetTaskRules/ExamSetTaskRules.vue"),
  },
  {
    path: "/exam/unionscanSheet",
    name: "unionscanSheet",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "扫描管理",
    },
    component: () => import("@/views/examUnion/scanSheet/scanSheet.vue"),
  },
  {
    path: "/exam/unionExamRanking",
    name: "unionExamRanking",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "临时榜",
    },
    component: () => import("@/views/examUnion/ExamRanking/ExamRanking.vue"),
  },
  {
    path: "/exam/ExamAbnormalScore",
    name: "ExamAbnormalScore",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "临时榜 / 检查异常分数",
    },
    component: () => import("@/views/examUnion/ExamAbnormalScore/index.vue"),
  },
  {
    path: "/exam/unionExamPaperAnswer",
    name: "unionExamPaperAnswer",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "答案设置",
    },
    component: () => import("@/views/exam/ExamPaperAnswer/index.vue"),
  },
  {
    path: "/exam/unionExamNewTeacher",
    name: "unionExamNewTeacher",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "阅卷教师管理",
    },
    component: () => import("@/views/examUnion/ExamNewTeacher/index.vue"),
  },
  {
    path: "/exam/unionExamDistribute",
    name: "unionExamDistribute",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "考务分工",
    },
    component: () =>
      import("@/views/examUnion/ExamDistribute/ExamDistribute.vue"),
  },
  {
    path: "/exam/unionExamMatchTheCandidate",
    name: "unionExamMatchTheCandidate",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "匹配考生",
    },
    component: () =>
      import(
        "@/views/examUnion/ExamMatchTheCandidate/ExamMatchTheCandidate.vue"
      ),
  },
  {
    path: "/exam/unionMarkingTask",
    name: "unionMarkingTask",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "阅卷开关",
    },
    component: () =>
      import("@/views/examUnion/ExamMarkingTask/MarkingTask.vue"),
  },
  {
    path: "/exam/unionExamStudentScore",
    name: "unionExamStudentScore",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "调整成绩",
    },
    component: () =>
      import("@/views/examUnion/ExamStudentScore/ExamStudentScore.vue"),
  },
  {
    path: "/exam/unionpaperMonitor",
    name: "unionpaperMonitor",
    meta: {
      name: "/exam/exam",
      newViews: true,
      keepAlive: true,
    },
    component: () => import("@/views/examUnion/paperMonitor/index.vue"),
    children: [
      {
        path: "/exam/unionMarkingProgress",
        name: "unionMarkingProgress",
        meta: {
          name: "/exam/exam",
          newViews: true,
          keepAlive: true,
          titleSub: "阅卷进度",
        },
        component: () =>
          import(
            "@/views/examUnion/paperMonitor/MarkingProgress/MarkingProgress.vue"
          ),
      },
      {
        path: "/exam/unionIsReading",
        name: "unionIsReading",
        meta: {
          name: "/exam/exam",
          newViews: true,
          keepAlive: true,
          titleSub: "阅卷进度",
        },
        component: () => import("@/views/examUnion/paperMonitor/IsReading.vue"),
      },
      {
        path: "/exam/unionSchoolMarkingProgress",
        name: "unionSchoolMarkingProgress",
        meta: {
          name: "/exam/exam",
          newViews: true,
          titleSub: "阅卷进度",
        },
        component: () =>
          import("@/views/examUnion/paperMonitor/schoolMarkingProgress.vue"),
      },
      {
        path: "/exam/schoolProgress",
        name: "schoolProgress",
        meta: {
          name: "/exam/exam",
          newViews: true,
          titleSub: "阅卷进度",
          fName: "unionMarkingProgress",
          noSubItem: true,
        },
        component: () =>
          import(
            "@/views/examUnion/paperMonitor/MarkingProgress/schoolProgress.vue"
          ),
      },
      {
        path: "/exam/groupProgress",
        name: "groupProgress",
        meta: {
          name: "/exam/exam",
          newViews: true,
          titleSub: "阅卷进度",
          fName: "unionMarkingProgress",
          noSubItem: true,
        },
        component: () =>
          import(
            "@/views/examUnion/paperMonitor/MarkingProgress/groupProgress.vue"
          ),
      },
      {
        path: "/exam/unionGradingCurve",
        name: "unionGradingCurve",
        meta: {
          name: "/exam/exam",
          newViews: true,
          titleSub: "阅卷进度",
          keepAlive: true,
        },
        component: () =>
          import("@/views/examUnion/paperMonitor/GradingCurve.vue"),
      },
      {
        path: "/exam/unionSamplePaperInspection",
        name: "unionSamplePaperInspection",
        meta: {
          name: "/exam/exam",
          newViews: true,
          titleSub: "阅卷进度",
          keepAlive: true,
        },
        component: () =>
          import("@/views/examUnion/paperMonitor/SamplePaperInspection.vue"),
      },
      {
        path: "/exam/unionGradeQuality",
        name: "unionGradeQuality",
        meta: {
          name: "/exam/exam",
          newViews: true,
          keepAlive: true,
          titleSub: "阅卷进度",
        },
        component: () =>
          import("@/views/examUnion/paperMonitor/GradeQuality.vue"),
      },
      {
        path: "/exam/ExceptionHandling",
        name: "ExceptionHandling",
        meta: {
          name: "/exam/exam",
          newViews: true,
          keepAlive: true,
          titleSub: "阅卷进度",
          fName: "unionMarkingTheAlarm",
        },
        component: () =>
          import("@/views/examUnion/paperMonitor/ExceptionHandling/index.vue"),
      },
      {
        path: "/exam/consistency",
        name: "unionConsistency",
        meta: {
          name: "/exam/exam",
          newViews: true,
          titleSub: "阅卷进度",
          title: "个人前后一致性",
          keepAlive: true,
        },
        component: () =>
          import("@/views/examUnion/paperMonitor/consistency.vue"),
      },
      {
        path: "/exam/unionMarkingReappraisal",
        name: "unionMarkingReappraisal",
        meta: {
          name: "/exam/exam",
          newViews: true,
          titleSub: "阅卷进度",
          keepAlive: true,
        },
        component: () =>
          import("@/views/examUnion/paperMonitor/MarkingReappraisal.vue"),
      },
      {
        path: "/exam/unionMultipleMonitoring",
        name: "unionMultipleMonitoring",
        meta: {
          name: "/exam/exam",
          newViews: true,
          titleSub: "阅卷进度",
          keepAlive: true,
        },
        component: () =>
          import("@/views/examUnion/paperMonitor/MultipleMonitoring.vue"),
      },
      {
        path: "/exam/unionMarkingTheAlarm",
        name: "unionMarkingTheAlarm",
        meta: {
          name: "/exam/exam",
          newViews: true,
          titleSub: "阅卷进度",
          keepAlive: true,
        },
        component: () =>
          import("@/views/examUnion/paperMonitor/MarkingTheAlarm.vue"),
      },
      {
        path: "/exam/unionArbitrationMonitoring",
        name: "unionArbitrationMonitoring",
        meta: {
          name: "/exam/exam",
          newViews: true,
          keepAlive: true,
          titleSub: "阅卷进度",
        },
        component: () =>
          import("@/views/examUnion/paperMonitor/ArbitrationMonitoring.vue"),
      },
      {
        path: "/exam/unionMarkStudent",
        name: "unionMarkStudent",
        meta: {
          name: "/exam/exam",
          newViews: true,
          fName: "unionMarkingTheAlarm",
          linkKey: true,
          titleSub: "调整答题卡",
        },
        component: () =>
          import("@/views/examUnion/paperMonitor/MarkStudent.vue"),
      },
    ],
  },
];
