import { getStore, setStore, clearLogonStore } from "@/core/util/store";
import Cookie from "js-cookie";
// import { isURL, validatenull } from "@/util/validate";
import {
  loginByMobile,
  loginBySocial,
  loginByUsername,
  getUserInfo,
  logout,
  refreshToken,
} from "@/core/api/login";
import { encryption } from "@/core/util/util";

// import webiste from "@/core/const/website";
// import {resetRouter} from '@/router/router'
// import {getMenu, getTopMenu} from '@/api/admin/menu'

// function addPath(ele, first) {
//   const menu = webiste.menu;
//   const propsConfig = menu.props;
//   const propsDefault = {
//     label: propsConfig.label || "name",
//     path: propsConfig.path || "path",
//     icon: propsConfig.icon || "icon",
//     children: propsConfig.children || "children"
//   };
//   const icon = ele[propsDefault.icon];
//   ele[propsDefault.icon] = validatenull(icon) ? menu.iconDefault : icon;
//   const isChild =
//     ele[propsDefault.children] && ele[propsDefault.children].length !== 0;
//   if (!isChild) ele[propsDefault.children] = [];
//   if (!isChild && first && !isURL(ele[propsDefault.path])) {
//     ele[propsDefault.path] = ele[propsDefault.path] + "/index";
//   } else {
//     ele[propsDefault.children].forEach(child => {
//       addPath(child);
//     });
//   }
// }

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

const auth = {
  state: {
    isMobile: false,
    userInfo:
      getStore({
        name: "userInfo",
      }) || {},
    permissions:
      getStore({
        name: "permissions",
      }) || [],
    roles: [],
    menu:
      getStore({
        name: "menu",
      }) || [],
    menuAll: [],
    expires_in:
      getStore({
        name: "expires_in",
      }) || "",
    access_token:
      getStore({
        name: "access_token",
      }) || "",
    school_id:
      getStore({
        name: "school_id",
      }) || "",
    refresh_token:
      getStore({
        name: "refresh_token",
      }) || "",
    isAuthenticated:
      (getStore({
        name: "access_token",
      }) || "") !== "",
  },
  actions: {
    // 根据用户名登录
    [LOGIN]({ commit }, userInfo) {
      const user = encryption({
        data: userInfo,
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });
      return new Promise((resolve, reject) => {
        loginByUsername(user.username, user.password, user.code, user.randomStr)
          .then((response) => {
            const data = response.data;
            commit("SET_ACCESS_TOKEN", data.access_token);
            commit("SET_SCHOOL_ID", data.user_info.schoolId);
            commit("SET_REFRESH_TOKEN", data.refresh_token);
            commit("SET_EXPIRES_IN", data.expires_in);
            commit("SET_USER_INFO", data.user_info);
            commit("SET_PERMISSIONS", data.user_info.authorities || []);
            // commit("CLEAR_LOCK");
            resolve(data.user_info.schoolId);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [VERIFY_AUTH]() {},
    // 根据手机号登录
    LoginByPhone({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByMobile(userInfo.mobile, userInfo.code)
          .then((response) => {
            const data = response.data;
            commit("SET_ACCESS_TOKEN", data.access_token);
            commit("SET_REFRESH_TOKEN", data.refresh_token);
            commit("SET_EXPIRES_IN", data.expires_in);
            commit("SET_USER_INFO", data.user_info);
            commit("SET_PERMISSIONS", data.user_info.authorities || []);
            // commit("CLEAR_LOCK");
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 根据OpenId登录
    LoginBySocial({ commit }, param) {
      return new Promise((resolve, reject) => {
        loginBySocial(param.state, param.code)
          .then((response) => {
            const data = response.data;
            commit("SET_ACCESS_TOKEN", data.access_token);
            commit("SET_REFRESH_TOKEN", data.refresh_token);
            commit("SET_EXPIRES_IN", data.expires_in);
            commit("SET_USER_INFO", data.user_info);
            commit("SET_PERMISSIONS", data.user_info.authorities || []);
            // commit("CLEAR_LOCK");
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 刷新token
    RefreshToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        refreshToken(state.refresh_token)
          .then((response) => {
            const data = response.data;
            commit("SET_ACCESS_TOKEN", data.access_token);
            commit("SET_REFRESH_TOKEN", data.refresh_token);
            commit("SET_EXPIRES_IN", data.expires_in);
            // commit("CLEAR_LOCK");
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 查询用户信息
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then((res) => {
            const data = res.data.data || {};
            commit("SET_USER_INFO", data.sysUser);
            commit("SET_ROLES", data.roles || []);
            commit("UPDATE_PERMISSIONS", data.permissions || []);
            resolve(data);
          })
          .catch(() => {
            reject();
          });
      });
    },
    // 登出
    [LOGOUT]({ commit }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(() => {
            // resetRouter();
            commit("SET_MENU", []);
            commit("SET_PERMISSIONS", []);
            commit("SET_USER_INFO", {});
            commit("SET_ACCESS_TOKEN", "");
            commit("SET_REFRESH_TOKEN", "");
            commit("SET_EXPIRES_IN", "");
            commit("SET_ROLES", []);
            // commit("DEL_ALL_TAG");
            // commit("CLEAR_LOCK");
            resolve();

            clearLogonStore();
            Cookie.clear();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 注销session
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        // resetRouter();
        commit("SET_MENU", []);
        commit("SET_PERMISSIONS", []);
        commit("SET_USER_INFO", {});
        commit("SET_ACCESS_TOKEN", "");
        commit("SET_REFRESH_TOKEN", "");
        commit("SET_ROLES", []);
        // commit("DEL_ALL_TAG");
        // commit("CLEAR_LOCK");
        resolve();
      });
    },
    // 获取系统菜单
    GetMenu() {
      return new Promise(() => {
        // getMenu(obj.id).then((res) => {
        //   const data = res.data.data
        //   const menu = deepClone(data)
        //   menu.forEach(ele => {
        //     addPath(ele)
        //   })
        //   let type = obj.type
        //   commit('SET_MENU', {type, menu})
        //   resolve(menu)
        // })
      });
    },
    //顶部菜单
    GetTopMenu() {
      return new Promise(() => {
        // getTopMenu().then((res) => {
        //   const data = res.data.data || []
        //   resolve(data)
        // })
      });
    },
  },
  mutations: {
    SET_ISMOBILE(state, payload) {
      state.isMobile = payload;
    },
    SET_ACCESS_TOKEN: (state, access_token) => {
      state.access_token = access_token;
      state.isAuthenticated = !!access_token;
      setStore({
        name: "access_token",
        content: state.access_token,
      });
    },
    SET_SCHOOL_ID: (state, school_id) => {
      state.school_id = school_id;
      setStore({
        name: "school_id",
        content: state.school_id,
      });
    },
    SET_EXPIRES_IN: (state, expires_in) => {
      state.expires_in = expires_in;
      setStore({
        name: "expires_in",
        content: state.expires_in,
      });
    },
    SET_REFRESH_TOKEN: (state, rfToken) => {
      state.refresh_token = rfToken;
      setStore({
        name: "refresh_token",
        content: state.refresh_token,
      });
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo;
      setStore({
        name: "userInfo",
        content: userInfo,
      });
    },
    SET_OLD_SCHOOLID: (state, oldSchoolId) => {
      state.oldSchoolId = oldSchoolId;
      setStore({
        name: "oldSchoolId",
        content: oldSchoolId,
      });
    },
    SET_MENU: (state, params = {}) => {
      let { menu, type } = params;
      if (type !== false) state.menu = menu;
      setStore({
        name: "menu",
        content: menu,
      });
    },
    SET_MENU_ALL: (state, menuAll) => {
      state.menuAll = menuAll;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PERMISSIONS: (state, permissions) => {
      const list = {};
      for (let i = 0; i < permissions.length; i++) {
        list[permissions[i].authority] = true;
      }

      state.permissions = list;
      setStore({
        name: "permissions",
        content: list,
      });
    },
    UPDATE_PERMISSIONS: (state, permissions) => {
      const list = {};
      for (let i = 0; i < permissions.length; i++) {
        list[permissions[i]] = true;
      }

      state.permissions = list;
      setStore({
        name: "permissions",
        content: list,
      });
    },
  },
};

export default auth;
