export const studentsHomework = [
  // {
  //   path: "/sheet/list",
  //   name: "SheetList",
  //   meta: {
  //     name: "/sheet/list",
  //     newViews: true,
  //   },
  //   component: () => import("@/views/sheet/list.vue"),
  // },
  {
    path: "/homework/list",
    name: "homework",
    meta: {
      name: "/homework/list",
      newViews: true,

      title: "智慧作业",
    },
    component: () => import("@/views/studentsHomework/list/index.vue"),
  },
  {
    path: "/homework/report",
    name: "/homework/report",
    meta: {
      name: "/homework/list",

      newViews: true,
      title: "智慧作业",
    },
    component: () => import("@/views/studentsHomework/report/index.vue"),
  },
  {
    path: "/homework/commentary",
    name: "/homework/commentary",
    meta: {
      name: "/homework/list",
      newViews: true,
      title: "智慧作业",
    },
    component: () => import("@/views/studentsHomework/commentary/index.vue"),
  },
  {
    path: "/homework/recycle",
    name: "/homework/recycle",
    meta: {
      name: "/homework/list",
      newViews: true,
      title: "智慧作业",
    },
    component: () => import("@/views/studentsHomework/recycle/index.vue"),
  },
  {
    path: "/homework/card",
    name: "/homework/card",
    meta: {
      name: "/homework/list",
      newViews: true,

      title: "查看作业卡",
    },
    component: () => import("@/views/studentsHomework/card/index.vue"),
  },
];
