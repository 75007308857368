/**
 * 通过 Value 和 Options 获取 Label
 * @param value 选中值
 * @param options Value-Label 型数组
 * @returns {string} 显示值
 */
import { getStore } from "@/core/util/store";
export const dashboardRouter = [
  {
    url: "/dashboard/teacher",
    type: 1,
    name: "校领导工作台",
    roleId: 5,
  },
  {
    url: "/dashboard/grade/teacher",
    type: 1,
    name: "年级组长工作台",
    roleId: 4,
  },
  {
    url: "/dashboard/preparation/teacher",
    type: 1,
    name: "备课组长工作台",
    roleId: 3,
  },
  {
    url: "/dashboard/classLeader/teacher",
    type: 1,
    name: "班主任工作台",
    roleId: 2,
  },
  {
    url: "/dashboard/courseTeacher/teacher",
    type: 1,
    name: "任课教师工作台",
    roleId: 1,
  },
];
export const eleccombList = [
  "物化地",
  "物化政",
  "物化生",
  "物政地",
  "物生地",
  "物生政",
  "历化生",
  "历地化",
  "历地生",
  "历政化",
  "历政地",
  "历政生",
];
export function levelOptions() {
  let userInfo = getStore({ name: "userInfo" });
  let levelOptions = new Array();
  let levelArr = [
    {
      value: "1",
      label: "小学",
    },
    {
      value: "2",
      label: "初中",
    },
    {
      value: "3",
      label: "高中",
    },
    {
      value: "4",
      label: "大学",
    },
  ];

  let schoolPeriod = userInfo.schoolPeriod;
  if (userInfo.roleId == 5) {
    schoolPeriod = userInfo.levelId;
  }
  if (!schoolPeriod) {
    levelOptions = levelArr;
  } else {
    levelArr.map((index) => {
      // console.log(schoolPeriod.indexOf(index.value));
      if (schoolPeriod.indexOf(index.value) != -1) {
        levelOptions.push(index);
      }
    });
  }
  // console.log(levelOptions);
  return levelOptions;
}
export function getLabel(value, options) {
  let label = "";
  for (let oi = 0; oi < options.length; oi++) {
    if (options[oi].value == value) {
      label = options[oi].label;
      break;
    }
  }
  return label;
}
export const examModeOptions = [
  {
    label: "普通考试",
    value: 0,
  },
  {
    label: "新高考",
    value: 1,
  },
];
export const subjectCategoryOptions = [
  // 1-考试科目 2-合并科目 3-合并取高科目 4-拆分科目
  {
    value: 1,
    label: "考试科目",
  },
  {
    value: 2,
    label: "合并科目",
  },
  {
    value: 3,
    label: "合并取高科目",
  },
  {
    value: 4,
    label: "拆分科目",
  },
];

export const gradeHighOptions = [
  {
    value: 500,
    label: "高一",
  },
  {
    value: 600,
    label: "高二",
  },
  {
    value: 700,
    label: "高三",
  },
];

// 大学
export const gradeUniversity = [
  {
    value: 1100,
    label: "大一",
  },
  {
    value: 1200,
    label: "大二",
  },
  {
    value: 1300,
    label: "大三",
  },
  {
    value: 1400,
    label: "大四",
  },
];

export const gradeMiddleOptions = [
  {
    value: 200,
    label: "初一",
  },
  {
    value: 300,
    label: "初二",
  },
  {
    value: 400,
    label: "初三",
  },
];

export const primaryOptions = [
  {
    value: 110,
    label: "一年级",
  },
  {
    value: 120,
    label: "二年级",
  },
  {
    value: 130,
    label: "三年级",
  },
  {
    value: 140,
    label: "四年级",
  },
  {
    value: 150,
    label: "五年级",
  },
  {
    value: 160,
    label: "六年级",
  },
];

export const gradeOptions = new Array()
  .concat(gradeHighOptions)
  .concat(gradeMiddleOptions)
  .concat(primaryOptions);
export const electiveCourse = [
  {
    value: 6,
    label: "政治",
    index: 7,
  },
  { value: 11, label: "道德与法治" },
  {
    value: 4,
    label: "历史",
    index: 8,
  },
  {
    value: 5,
    label: "地理",
    index: 9,
  },
  {
    value: 8,
    label: "物理",
    index: 4,
  },
  {
    value: 9,
    label: "化学",
    index: 5,
  },
  {
    value: 7,
    label: "生物",
    index: 6,
  },
];

// 选考组合科目的id
export const optionalSubject = [4, 5, 6, 7, 8, 9];

export const subjectOptions = [
  {
    value: 1,
    label: "语文",
    index: 1,
  },
  {
    value: 2,
    label: "数学",
    index: 2,
  },
  {
    value: 3,
    label: "英语",
    index: 3,
  },
  {
    value: 6,
    label: "政治",
    abbreviation: "政",
    index: 7,
  },
  { value: 11, label: "道德与法治" },
  {
    value: 4,
    label: "历史",
    abbreviation: "历",
    index: 8,
  },
  {
    value: 5,
    label: "地理",
    abbreviation: "地",
    index: 9,
  },
  {
    value: 8,
    label: "物理",
    abbreviation: "物",
    index: 4,
  },
  {
    value: 9,
    label: "化学",
    abbreviation: "化",
    index: 5,
  },
  {
    value: 7,
    label: "生物",
    abbreviation: "生",
    index: 6,
  },

  // {
  //   value: 10,
  //   label: "科学"
  // },
  // {
  //   value: 11,
  //   label: "道德与法治"
  // }
];

export const examTypeOptions = [
  {
    value: 1,
    label: "单元测试",
  },
  {
    value: 2,
    label: "月考",
  },
  {
    value: 3,
    label: "期中考",
  },
  {
    value: 4,
    label: "期末考",
  },
  {
    value: 5,
    label: "周考",
  },
  {
    value: 6,
    label: "模拟考",
  },
  {
    value: 7,
    label: "专题练习",
  },
  {
    value: 8,
    label: "入学测验",
  },
  {
    value: 9,
    label: "水平会考",
  },
  {
    value: 10,
    label: "自主招生",
  },
  {
    value: 11,
    label: "联考",
  },
  {
    value: 12,
    label: "历年真题",
  },
  {
    value: 13,
    label: "竞赛题",
  },
  {
    value: 14,
    label: "课时练习",
  },
  {
    value: 15,
    label: "学业考试",
  },
  {
    value: 16,
    label: "联考",
  },
  {
    value: 17,
    label: "作业",
  },
  {
    value: 99,
    label: "其他",
  },
  {
    value: 101,
    label: "学科网组卷",
  },
];
export const eliteSchoolOptions = [
  {
    value: 1,
    label: "单元测试",
  },
  {
    value: 2,
    label: "月考",
  },
  {
    value: 3,
    label: "期中考",
  },
  {
    value: 4,
    label: "期末考",
  },
  {
    value: 6,
    label: "模拟考",
  },
  {
    value: 16,
    label: "联考",
  },
];

export const reportTypeOptions = [
  {
    value: 0,
    label: "阶段复习",
  },
  {
    value: 1,
    label: "单次考试",
  },
];

export const examStateOptions = [
  {
    value: 1,
    label: "进行中",
  },
  {
    value: 2,
    label: "已结束",
  },
  {
    value: 3,
    label: "已统计",
  },
];

export const examYearOptions = [
  {
    value: "0",
    label: "全部",
  },
  {
    value: "2021",
    label: "2021 年",
  },
  {
    value: "2020",
    label: "2020 年",
  },
  {
    value: "2019",
    label: "2019 年",
  },
  {
    value: "2018",
    label: "2018 年",
  },
  {
    value: "2017",
    label: "2017 年",
  },
  {
    value: "2016",
    label: "2016 年",
  },
  {
    value: "2015",
    label: "2015 年",
  },
  {
    value: "2014",
    label: "2014 年",
  },
  {
    value: "2013",
    label: "2013 年",
  },
  {
    value: "2012",
    label: "2012 年",
  },
  {
    value: "2011",
    label: "2011 年",
  },
  {
    value: "2010",
    label: "2010 年",
  },
  {
    value: "2009",
    label: "2009 年",
  },
];

export const oldLevelOptions = [
  {
    value: "1",
    label: "小学",
  },
  {
    value: "2",
    label: "初中",
  },
  {
    value: "3",
    label: "高中",
  },
];

export const schooleYearOptions = [
  {
    value: "2022",
    label: "2022",
  },
  {
    value: "2021",
    label: "2021",
  },
  {
    value: "2020",
    label: "2020",
  },
  {
    value: "2019",
    label: "2019",
  },
  {
    value: "2018",
    label: "2018",
  },
  {
    value: "2017",
    label: "2017",
  },
];

export const examSecurityLevelOptions = [
  { value: 1, label: "低风险" },
  { value: 2, label: "高风险" },
];

export const examReportAutoSendOptions = [
  // { value: "1", label: "报告自动发布" },
  { value: "2", label: "报告自动发布到老师" },
  { value: "3", label: "报告自动发布到学生" },
];

export const reportFormats = [
  { value: 1, label: "word" },
  { value: 2, label: "pdf", disabled: false },
];

export const report_units = [
  { value: 1, label: "班级" },
  { value: 2, label: "年级" },
];

export const report_user_types = [
  { value: 1, label: "学生" },
  { value: 2, label: "老师" },
];

export const optionsTime = [
  {
    value: 7,
    label: "近一周",
  },
  {
    value: 30,
    label: "近一个月",
  },
  {
    value: 90,
    label: "近三个月",
  },
  {
    value: 180,
    label: "近半年",
  },
  {
    value: 360,
    label: "近一年",
  },

  {
    value: 0,
    label: "所有",
  },
];

export const difficultyListCopy = [
  {
    value: 1,
    label: "易",
  },
  {
    value: 0.9,
    label: "易",
  },
  {
    value: 0.8,
    label: "易",
  },
  {
    value: 0.7,
    label: "较易",
  },
  {
    value: 0.6,
    label: "较易",
  },
  {
    value: 0.5,
    label: "中档",
  },
  {
    value: 0.4,
    label: "中档",
  },
  {
    value: 0.3,
    label: "较难",
  },
  {
    value: 0.2,
    label: "较难",
  },
  {
    value: 0.1,
    label: "难",
  },
  {
    value: 0,
    label: "难",
  },
];

export const difficultyList = [
  {
    value: 0.9,
    label: "易",
  },
  {
    value: 0.7,
    label: "较易",
  },
  {
    value: 0.5,
    label: "中档",
  },
  {
    value: 0.3,
    label: "较难",
  },
  {
    value: 0.1,
    label: "难",
  },
];

// 考评范围
export const evaluationRange = [
  {
    value: 0,
    label: "全体学生",
  },
  {
    value: 1,
    label: "住校生",
  },
  {
    value: 2,
    label: "走读生",
  },
];
// 教师角色列表
export const teacherRoleList = [
  {
    value: 1,
    label: "任课教师 ",
  },
  {
    value: 2,
    label: "班主任",
  },
  {
    value: 6,
    label: "宿管老师",
  },
  {
    value: 7,
    label: "排课老师",
  },
];

// 教师角色列表
export const teacherRole = [
  {
    value: 8,
    label: "教务",
  },
  {
    value: 7,
    label: "排课老师",
  },
  {
    value: 6,
    label: "宿管",
  },
  {
    value: 5,
    label: "校领导",
  },
  {
    value: 4,
    label: "年级组长",
  },
  {
    value: 3,
    label: "备课组长",
  },
  {
    value: 2,
    label: "班主任",
  },
  {
    value: 1,
    label: "任课教师",
  },
];

// 考生类型列表
export const studentTypeList = [
  {
    label: "所有考生",
    value: 1,
  },
  {
    label: "重学籍号考生",
    value: 2,
  },
  {
    label: "无学籍号考生",
    value: 3,
  },
];

// 考生匹配状态
export const matchingList = [
  {
    label: "待匹配",
    value: -1,
  },
  {
    label: "成功",
    value: 0,
  },
  {
    label: "失败",
    value: 1,
  },
  {
    label: "有质疑",
    value: 2,
  },
];
// 框选类型
export const markTypeList = [
  {
    value: 1,
    label: "框选定位信息",
    key: "markLocation",
  },
  {
    value: 2,
    label: "框选标题",
    key: "markTitle",
  },
  {
    value: 3,
    label: "框选考号",
    key: "markExamNo",
  },
  {
    value: 4,
    label: "框选客观题",
    key: "markObjective",
  },
  {
    value: 5,
    label: "框选主观题",
    key: "markSubjective",
  },
  {
    value: 6,
    label: "框选其他标记",
    key: "markOther",
  },
];

// 考号类型
export const optionsTypeList = [
  {
    label: "填涂式",
    value: 1,
  },
  {
    label: "条形码",
    value: 2,
  },
  {
    label: "手写考号",
    value: 3,
  },
];

// 选择题类型
export const choiceQuestionTypeList = [
  {
    label: "单选题",
    value: 1,
  },
  {
    label: "多选题",
    value: 2,
  },
  {
    label: "判断题",
    value: 3,
  },
];

// 排列方式
export const optionsArrangeTypeList = [
  {
    label: "横向",
    value: 1,
  },
  {
    label: "纵向",
    value: 2,
  },
];

// 考号顺序
export const studentExamIdStyleList = [
  {
    label: "0 1 2 3 4 5 6 7 8 9",
    value: 1,
  },
  {
    label: "1 2 3 4 5 6 7 8 9 0",
    value: 2,
  },
];

export const errTypeList = [
  {
    value: 1,
    label: "纸张正常",
  },
  {
    value: 2,
    label: "图像不完整",
  },
  {
    value: 3,
    label: "定位异常",
  },
  {
    value: 4,
    label: "页码异常",
  },
  {
    value: 5,
    label: "学科异常",
  },
  {
    value: 6,
    label: "考号填涂异常",
  },
  {
    value: 7,
    label: "考号重复",
  },
  {
    value: 8,
    label: "待上传",
  },
  {
    value: 9,
    label: "待识别",
  },
  {
    value: 10,
    label: "识别中",
  },
  {
    value: 11,
    label: "AB卡异常",
  },
  {
    value: 12,
    label: "其他填涂异常",
  },
  {
    value: 13,
    label: "缺考异常",
  },
  {
    value: 14,
    label: "客观题异常",
  },
  {
    value: 15,
    label: "标题异常",
  },
  {
    value: 16,
    label: "主观题异常",
  },
  {
    value: 17,
    label: "确认缺考",
  },
  {
    value: 18,
    label: "选做题异常",
  },
  {
    value: 99,
    label: "识别异常",
  },
];

// 异常类型
export const exceptionTypes = [
  { value: 1, label: "答案内容显示不全" },
  { value: 2, label: "文字看不清楚" },
  { value: 3, label: "答题错位" },
  { value: 4, label: "非本科试卷" },
  { value: 5, label: "雷同卷" },
];

export const optionListT = ["T", "F"];

// 选项个数
export const optionItem = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

//题型
export const questionType = [
  {
    type: "1",
    name: "语文",
    val: [
      "论述类文本阅读",
      "实用类文本阅读",
      "文学类文本阅读",
      "文言文阅读",
      "古代诗歌阅读",
      "名篇名句默写",
      "语言文字运用",
      "作文题",
    ],
  },
  {
    type: "2",
    name: "数学",
    val: ["选择题", "填空题", "解答题"],
  },
  {
    type: "3",
    name: "英语",
    val: [
      "听力题",
      "阅读理解",
      "七选五",
      "完形填空",
      "语法填空",
      "短文改错",
      "作文题",
    ],
  },
  {
    type: "4",
    name: "历史",
    val: ["选择题", "填空题", "简答题"],
  },
  {
    type: "5",
    name: "地理",
    val: ["选择题", "填空题", "简答题"],
  },
  {
    type: "6",
    name: "政治",
    val: ["选择题", "填空题", "简答题"],
  },
  {
    type: "7",
    name: "物理",
    val: ["选择题", "单选题", "多选题", "填空题", "作图题", "解答题"],
  },
  {
    type: "8",
    name: "物理",
    val: ["选择题", "填空题", "解答题"],
  },
  {
    type: "9",
    name: "化学",
    val: ["选择题", "填空题", "解答题"],
  },
];

export const schoolLevelOptions = [
  {
    value: 0,
    label: "普通学校",
  },
  {
    value: 5,
    label: "一类示范校",
  },
  {
    value: 2,
    label: "二类示范校",
  },
];

// 阅卷分工类型
export const scoreRulesList = [
  {
    value: 1,
    label: "单评",
  },
  {
    value: 2,
    label: "双评+仲裁",
  },
  {
    value: 3,
    label: "2+1+仲裁",
  },
];
// 小数位数
export const decimalDigits = [
  {
    value: 1,
    label: "取整",
  },
  {
    value: 2,
    label: "保留一位小数",
  },
  {
    value: 3,
    label: "保留两位小数",
  },
];
// 进位方式
export const digitsMethod = [
  {
    value: 1,
    label: "四舍五入",
  },
  {
    value: 2,
    label: "全入",
  },
  {
    value: 3,
    label: "全舍",
  },
  {
    value: 4,
    label: "取0.5就高",
  },
  {
    value: 5,
    label: "取0.5就低",
  },
];
// 给分方式
export const scoreMethod = [
  {
    value: 1,
    label: "计算器给分",
  },
  {
    value: 2,
    label: "一键给分",
  },
  {
    value: 3,
    label: "分项给分",
  },
];

// 分项给分方式
export const scoreTypeItem = [
  {
    value: 1,
    label: "区域打分",
  },
  {
    value: 2,
    label: "自由打分",
  },
];
// 步长
export const stepSize = [
  {
    value: 1,
    label: "设置步长",
  },
  {
    value: 2,
    label: "自定义给分点",
  },
];

export const readTypeList = [
  {
    value: 0,
    label: "未阅卷",
  },
  {
    value: 1,
    label: "部分阅卷",
  },
  {
    value: 3,
    label: "未阅完",
  },
  {
    value: 2,
    label: "已阅完",
  },
];
export const examClassList = [
  { value: 1, label: "行政班" },
  { value: 2, label: "教学班" },
];

/*                             试卷步骤                             */
/**
 * 上传试卷
 */
export const STEP_UPLOADEXAMPAPER = 1;
/**
 * 裁切试题
 */
export const STEP_DIVIDEEXAMQUESTION = 2;
/**
 * 标注知识点
 */
export const STEP_MARKKNOWLEDGEPOINT = 3;
/**
 * 匹配变式题
 */
export const STEP_MATCHVARIATIONQUESTION = 4;
/**
 * 已完成所有步骤
 */
export const STEP_FINISHALL = 9;
