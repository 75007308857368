const state = {
  // 扫描异常列表
  scanErrList: [],
  // 当前选中的异常
  indexScanErr: {},
  // 当前是哪种异常
  indexScanType: 2,
  // 科目列表
  subjectList: [],
  // 异常数量列表
  abnormalList: [],
  // 处理状态
  isHandle: 0,
  // 缺考异常选中的id
  signErrList: [],
  total: 0,
  current: 1,
  isBatch: false,
  checkAllList: [],
  sheetInfomation: {},
  sheetmarkList: [],
  indexsheetmarkList: [],
  timeIndex: 0,
};
const actions = {};
const mutations = {
  SET_SHEET_INFOMATION(state, val) {
    state.sheetInfomation = val;
  },
  SET_SHEET_MARK_LIST(state, val) {
    state.sheetmarkList = val;
  },
  SET_INDEX_SHEET_MARK_LIST(state, val) {
    state.indexsheetmarkList = val;
  },
  SET_INDEX_TIME(state, val) {
    state.timeIndex = val;
  },
  SET_TOTAL(state, val) {
    state.total = val;
  },
  SET_CHECK_ALL(state, val) {
    state.checkAllList = val;
  },
  SET_ISBATCH(state, val) {
    state.isBatch = val;
  },
  SET_CURRENT(state, val) {
    state.current = val;
  },
  SET_INDEX_URL(state, val) {
    state.scanErrList.map((item) => {
      // console.log(val);
      val.map((index) => {
        if (item.id == index.id) {
          Object.assign(item, index);
        }
      });
    });
  },
  SET_SCAN_ERR_LIST(state, val) {
    state.scanErrList = val;
  },
  SET_INDEX_SCAN_ERR(state, val) {
    state.indexScanErr = val;
  },
  SET_INDEX_SCAN_TYPE(state, val) {
    state.indexScanType = val;
  },
  SET_SUBJECT_LIST(state, val) {
    state.subjectList = val;
  },
  SET_ABNORMAL_LIST(state, val) {
    state.abnormalList = val;
  },
  SET_IS_HANDLE(state, val) {
    state.isHandle = val;
  },
  SET_SIGN_ERR_LIST(state, val) {
    state.signErrList = val;
  },
};
const getters = {
  GET_SHEET_MARK_LIST_SCAN(state) {
    return state.sheetmarkList;
  },
  GET_INDEX_SHEET_MARK_LIST_SCAN(state) {
    return state.indexsheetmarkList;
  },
  GET_INDEX_TIME(state) {
    return state.timeIndex;
  },
  GET_SHEET_INFOMATION(state) {
    // console.log(state.sheetInfomation);
    return state.sheetInfomation;
  },
  GET_ISBATCH(state) {
    return state.isBatch;
  },
  GET_CHECK_ALL(state) {
    return state.checkAllList;
  },
  GET_TOTAL(state) {
    return state.total;
  },
  GET_CURRENT(state) {
    return state.current;
  },
  GET_SCAN_ERR_LIST(state) {
    return state.scanErrList;
  },
  GET_INDEX_SCAN_ERR(state) {
    return state.indexScanErr;
  },
  GET_INDEX_SCAN_TYPE(state) {
    return state.indexScanType;
  },
  GET_SUBJECT_LIST(state) {
    return state.subjectList;
  },
  GET_ABNORMAL_LIST(state) {
    return state.abnormalList;
  },
  GET_IS_HANDLE(state) {
    return state.isHandle;
  },
  GET_SIGN_ERR_LIST(state) {
    return state.signErrList;
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
};
