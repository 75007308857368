Array.prototype.flat = function (count) {
  // 重写flat方法
  let c = count || 1;
  let len = this.length;
  let ret = [];
  if (this.length == 0) return this;
  while (c--) {
    let _arr = [];
    let flag = false;
    if (ret.length == 0) {
      flag = true;
      for (let i = 0; i < len; i++) {
        if (this[i] instanceof Array) {
          ret.push(...this[i]);
        } else {
          ret.push(this[i]);
        }
      }
    } else {
      for (let i = 0; i < ret.length; i++) {
        if (ret[i] instanceof Array) {
          flag = true;
          _arr.push(...ret[i]);
        } else {
          _arr.push(ret[i]);
        }
      }
      ret = _arr;
    }
    if (!flag && c == Infinity) {
      break;
    }
  }
  return ret;
};
Array.prototype.flatMap = function (cb) {
  if (typeof cb !== "function") {
    throw new TypeError("Callback must be a function");
  }

  var _this = this,
    arg2 = arguments[1],
    res = [],
    item;
  for (var i = 0; i < _this.length; i++) {
    item = cb.apply(arg2, [_this[i], i, _this]);
    item && res.push(item);
  }

  return res.flat();
};
