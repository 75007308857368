import { setStore } from "@/core/util/store";
const state = {
  //学段
  levelId: "",
  //科目
  subjectId: "",
  //刷新组件
  isType: false,
  //是否显示
  isShowMenu: true,
  // 外部页面更新组件内的值
  editObject: {
    levelId: "",
    subjectId: "",
  },
};
const actions = {};
const mutations = {
  setEditObject(state, val) {
    state.editObject = val;
  },
  setLevelId(state, val) {
    state.levelId = val;
    setStore({
      name: "levelId",
      content: { value: val },
    });
  },
  setSubjectId(state, val) {
    state.subjectId = val;
    setStore({
      name: "subjectId",
      content: { value: val },
    });
  },
  setIsType(state) {
    // console.log(state);
    if (state.isType) {
      state.isType = false;
    } else {
      state.isType = true;
    }
  },
  setIsShowMenu(state, value) {
    state.isShowMenu = value;
  },
};
const getters = {
  getEditObject(state) {
    return state.editObject;
  },
  getLevelId(state) {
    return state.levelId;
  },
  getSubjectId(state) {
    return state.subjectId;
  },
  getIsType(state) {
    return state.isType;
  },
  getIsShowMenue(state) {
    return state.isShowMenu;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
