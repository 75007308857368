export const other = [
  {
    path: "/other/student",
    name: "student",
    meta: {
      tabName: "other",
    },
    component: () => import("@/views/Other/student/index.vue"),
  },
  {
    path: "/other/teacher",
    name: "teacher",
    meta: {
      tabName: "other",
    },
    component: () => import("@/views/Other/teacher/index"),
  },
  {
    path: "/other/school",
    name: "School",
    meta: {
      tabName: "other",
    },
    component: () => import("@/views/Other/school/index"),
  },
];
