export const dashboard = [
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      name: "/dashboard",
      newViews: true,
      tabName: "dashboard",
    },
    component: () => import("@/views/dashboard/index.vue"),
  },
  {
    path: "/dashboard/admin",
    name: "/dashboard/admin",
    meta: {
      name: "/dashboard",
      newViews: true,
      tilte: "教务工作台",
    },
    component: () => import("@/views/dashboard/admin/index.vue"),
  },
  {
    path: "/dashboard/teacher",
    name: "/dashboard/teacher",
    meta: {
      name: "/dashboard",
      newViews: true,
      tilte: "校领导工作台",
    },
    component: () => import("@/views/dashboard/teacher/index.vue"),
  },
  {
    path: "/dashboard/preparation/teacher",
    name: "/dashboard/preparation/teacher",
    meta: {
      name: "/dashboard",
      newViews: true,
      tilte: "备课组长工作台",
    },
    component: () => import("@/views/dashboard/teacher/index.vue"),
  },
  {
    path: "/dashboard/grade/teacher",
    name: "/dashboard/grade/teacher",
    meta: {
      name: "/dashboard",
      newViews: true,
      tilte: "年级组长工作台",
    },
    component: () => import("@/views/dashboard/teacher/index.vue"),
  },
  {
    path: "/dashboard/classLeader/teacher",
    name: "/dashboard/classLeader/teacher",
    meta: {
      name: "/dashboard",
      newViews: true,
      tilte: "班主任工作台",
    },
    component: () => import("@/views/dashboard/teacher/index.vue"),
  },
  {
    path: "/dashboard/courseTeacher/teacher",
    name: "/dashboard/courseTeacher/teacher",
    meta: {
      name: "/dashboard",
      newViews: true,
      tilte: "班主任工作台",
    },
    component: () => import("@/views/dashboard/teacher/index.vue"),
  },
];
