export const resource = [
  // 资源中心-----start------------
  {
    path: "/res",
    name: "MyResIndex",
    meta: {
      name: "/res/resource",
      // keepAlive: true,
    },
    component: () => import("@/views/resource/myResIndex.vue"),
    redirect: "/res/knowledgePointsPaper",
    children: [
      {
        path: "/homework/myHomework",
        name: "/homework/myHomework",
        meta: {
          name: "/homework/myHomework",
          tabName: "myResource",
          title: "智慧作业",
        },
        component: () =>
          import("@/views/studentsHomework/myHomework/index.vue"),
      },
      {
        path: "/res/myresource",
        name: "myResource",
        meta: {
          name: "/res/boutique",
          tabName: "myResource",
        },
        component: () => import("@/views/resource/myResource/index.vue"),
      },
      {
        path: "/res/knowledgePointsPaper",
        name: "KnowledgePointsPaper",
        meta: {
          name: "/res/boutique",
          tabName: "multiplePaper",
          keepAlive: true,
        },
        component: () =>
          import("@/views/resource/knowledgePointsPaper/index.vue"),
      },
      {
        path: "/res/breakdownPaper",
        name: "BreakdownPaper",
        meta: {
          name: "/res/boutique",
          tabName: "multiplePaper",
        },
        component: () => import("@/views/resource/breakdownPaper/index.vue"),
      },
      {
        path: "/res/questionList",
        name: "QuestionList",
        meta: {
          name: "/res/boutique",
          tabName: "multiplePaper",
        },
        component: () =>
          import("@/views/resource/breakdownPaper/questionList.vue"),
      },
      {
        path: "/res/specificList",
        name: "SpecificList",
        meta: {
          name: "/res/boutique",
          tabName: "multiplePaper",
        },
        component: () =>
          import("@/views/resource/breakdownPaper/specificList.vue"),
      },
      {
        path: "/res/learningPaper",
        name: "LearningPaper",
        meta: {
          name: "/res/boutique",
          tabName: "multiplePaper",
          title: "学情组卷",
          newViews: true,
          // keepAlive: true,
        },
        component: () => import("@/views/resource/learningPaper/index.vue"),
      },
      {
        path: "/res/schoolResource",
        name: "schoolResource",
        meta: {
          name: "/res/boutique",
          tabName: "schoolResource",
        },
        component: () => import("@/views/resource/schoolResource/index.vue"),
      },
      // {
      //   path: "/res/boutique",
      //   name: "resource",
      //   meta: {
      //     name: "/res/resource"
      //   },
      //   component: () => import("@/views/resource/index.vue")
      // },
      {
        path: "/res/boutique",
        name: "boutique",
        meta: {
          name: "/res/boutique",
          tabName: "boutique",
          keepAlive: true,
        },
        // redirect: "/res/knowledgePointsPaper",
        component: () => import("@/views/resource/boutiqueLibrary/index.vue"),
      },
      {
        path: "/res/teachingAids",
        name: "boutiqueTeachingAids",
        meta: {
          name: "/res/resource",
          tabName: "boutiqueTeachingAids",
        },
        component: () =>
          import("@/views/resource/boutiqueTeachingAids/index.vue"),
      },
      {
        path: "/res/microLesson",
        name: "boutiqueMicroLesson",
        meta: {
          name: "/res/resource",
          tabName: "boutiqueMicroLesson",
        },
        component: () =>
          import("@/views/resource/boutiqueMicroLesson/index.vue"),
      },
    ],
  },
  //教案
  {
    path: "/res/teachingPlanDetail",
    name: "TeachingPlanDetails",
    meta: {
      name: "/res/boutique",
    },
    component: () =>
      import("@/views/resource/components/teachingPlanDetail.vue"),
  },
  //课件
  {
    path: "/res/coursewareDetail",
    name: "CoursewareDetail",
    meta: {
      name: "/res/boutique",
    },
    component: () => import("@/views/resource/components/coursewareDetail.vue"),
  },
  //教材
  {
    path: "/res/teachingMaterialDetail",
    name: "TeachingMaterialDetail",
    meta: {
      name: "/res/boutique",
    },
    component: () =>
      import("@/views/resource/components/teachingMaterialDetail.vue"),
  },
  //创建微课
  {
    path: "/res/createMicroLesson",
    name: "CreateMicroLesson",
    meta: {
      name: "/res/boutique",
      tabName: "myResource",
    },
    component: () =>
      import("@/views/resource/myResource/createMicroLesson.vue"),
  },
  //微课详情
  {
    path: "/res/showMicroLesson",
    name: "ShowMicroLesson",
    meta: {
      name: "/res/boutique",
      tabName: "myResource",
    },
    component: () => import("@/views/resource/myResource/showMicroLesson.vue"),
  },
  // {
  //   path: "/res/uploadResources",
  //   name: "UploadResources",
  //   meta: {
  //     name: "/res/resource"
  //   },
  //   component: () => import("@/views/resource/uploadResources/index.vue")
  // },
  {
    path: "/res/viewPaper",
    name: "viewPaper",
    meta: {
      name: "/res/boutique",
      newViews: true,
      // tabName: "/res/resource"
    },
    component: () => import("@/views/resource/boutiqueLibrary/ViewPaper.vue"),
  },
  {
    path: "/res/exampaperdivide",
    name: "resexampaperdivide",
    meta: {
      name: "/res/resource",
    },
    component: () => import("@/views/exam/ExamPaperDivide.vue"),
  },
  {
    path: "/res/exampaperpicdivide",
    name: "resexampaperpicdivide",
    meta: {
      name: "/res/resource",
    },
    component: () => import("@/views/exam/ExamPaperPicDivide.vue"),
  },
  {
    path: "/res/examlore",
    name: "resexamlore",
    meta: {
      name: "/res/resource",
      // keepAlive: true,
    },
    component: () => import("@/views/exam/ExamLore.vue"),
  },
  // 资源中心-----end------------
];
