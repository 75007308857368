<template>
  <div id="app" class="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
    <!-- <transition name="fade-transform">
        <keep-alive :include="allKeepAlive">
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
      </transition>
      <transition name="fade-transform">
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </transition> -->
    <refreshPage ref="refreshPage"></refreshPage>
  </div>
</template>
<script>
// import { LOGOUT } from "@/core/services/store/auth.module";
// import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import refreshPage from "@/components/common/refreshPage.vue";
import { mapGetters } from "vuex";
import { clearLogonStore, getStore, setStore } from "@/core/util/store";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  name: "App",
  components: { refreshPage },
  data() {
    return {
      allKeepAlive: [],
      nowTime: 1,
      locale: zhCN,
    };
  },
  computed: {
    ...mapGetters(["getErrApi", "getErrBox", "getKeepArr"]),
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.setView();
        });
      },
    },
    getKeepArr: {
      immediate: true,
      handler: function () {
        // console.log(this.getKeepArr);
        this.allKeepAlive = this.getKeepArr;
      },
    },
    getErrApi: {
      deep: true, //深度监听设置为 true
      handler: function () {
        // console.log(this.errApi);
        if (this.getErrApi.type == true) {
          let date = new Date().getTime();
          if (date - this.nowTime > 200) {
            this.nowTime = date;
            this.$message({
              message: this.getErrApi.text,
              type: "error",
              showClose: true,
              duration: 2000,
            });
            // this.$store.state.errApi.type = false;
            this.$store.commit("setErrApi", {
              type: false,
            });
          }

          // this.errApi.type = false;
        }
      },
    },
    getErrBox: {
      deep: true,
      handler: function () {
        if (this.getErrBox.type == true) {
          this.$confirm(this.getErrBox.text, "提示信息", {
            distinguishCancelAndClose: true,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            showClose: false,
          })
            .then(() => {
              // 清除用户信息
              setTimeout(() => {
                // this.$store.state.errBox.type = false;
                this.$store.commit("setErrBox", {
                  type: false,
                });
              }, 100);

              clearLogonStore();

              this.$router.push({ name: "login" });
              // window.location.reload();
              // this.$store
              //   .dispatch(LOGOUT)
              //   .then(() => this.$router.push({ name: "login" }));
            })
            .catch(() => {
              this.$store.commit("setErrBox", {
                type: false,
              });
              // console.log(err);
              // this.$store.state.errBox.type = false;
              // console.log("2222222")
            });
        }
      },
    },
  },
  created() {
    this.getScrollbarMaxWidth();

    // if (window.screen.width <= 1200) {
    //   document.getElementsByTagName("html")[0].style = `zoom:${
    //     window.screen.width / 1920
    //   }`;
    // }
  },
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    // this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
    this.$nextTick(() => {
      this.setView();
    });
    this.getStoreFn();
    this.getVersionFn();
  },
  updated() {
    this.getScrollbarMaxWidth();
    this.$nextTick(() => {
      this.setView();
    });
  },

  methods: {
    getVersionFn() {
      const web_version = getStore({ name: "web_version" });
      const { VUE_APP_VERSION } = process.env;
      if (!VUE_APP_VERSION) return;
      if (!web_version || web_version != VUE_APP_VERSION) {
        setStore({ name: "web_version", content: VUE_APP_VERSION });
        window.location.reload();
      }
    },
    // 跨页面通信  用户刷新了locationStore
    getStoreFn() {
      window.addEventListener("storage", (event) => {
        if (event.key === "jzjx-userInfo") {
          // window.location.reload();
          // 执行相应操作
          this.$refs.refreshPage.init();
        }
      });
    },
    isMobile() {
      var userAgentInfo = navigator.userAgent;
      var mobileAgents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];

      var mobile_flag = false;

      //根据userAgent判断是否是手机
      for (var v = 0; v < mobileAgents.length; v++) {
        if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
          mobile_flag = true;
          break;
        }
      }
      var screen_width = window.screen.width;
      var screen_height = window.screen.height;

      //根据屏幕分辨率判断是否是手机
      if (screen_width > 325 && screen_height < 750) {
        mobile_flag = true;
      }
      this.$store.commit("SET_ISMOBILE", mobile_flag);
      if (mobile_flag) {
        this.$alert(
          "目前检测到您在使用移动设备登录文通云，建议您使用移动端App访问",
          "提示",
          {
            confirmButtonText: "确定",
          }
        );
      }
    },
    //给元素添加-值
    getScrollbarMaxWidth() {
      // let odiv = document.createElement("div"), //创建一个div
      //   styles = {
      //     width: "100px",
      //     height: "100px",
      //     overflowY: "scroll", //让他有滚动条
      //   },
      //   i,
      //   scrollbarMaxWidth;
      // for (i in styles) odiv.style[i] = styles[i];
      // document.body.appendChild(odiv); //把div添加到body中
      // scrollbarMaxWidth = odiv.offsetWidth - odiv.clientWidth; //相减
      // odiv.remove(); //移除创建的div
      // this.scrollbarMaxWidth = scrollbarMaxWidth;
      // // 如果是全屏页面 就不设置marin
      // if (this.$route.meta.viewAll) {
      //   // document.getElementsByTagName("body").item(0).style.marginLeft = 0;
      //   document.getElementsByTagName("body").item(0).style.paddingLeft = 0;
      // } else {
      //   // document.getElementsByTagName("body").item(0).style.marginLeft =
      //   //   "-" + scrollbarMaxWidth + "px";
      //   document.getElementsByTagName("body").item(0).style.paddingLeft =
      //     "calc(100vw - 100%)";
      // }
      // this.$store.commit("setScrollYWidth", this.scrollbarMaxWidth);
    },
    setView() {
      // 如果是新页面 1720
      if (this.$route.meta.newViews) {
        document.getElementsByTagName("body").item(0).style.backgroundColor =
          "#f4f4f4";
        this.bodyWidth = true;
        // if (this.hasScrollbar()) {
        // this.paddingWidthNum = 90 - this.getScrollYWidth + "px";
        // } else {
        //   this.paddingWidthNum = "90px";
        // }
        // this.$nextTick(function () {
        //   this.$refs.ktContent.style.paddingLeft = this.paddingWidthNum;
        //   this.$refs.ktContent.style.paddingRight = this.paddingWidthNum;
        // });
      } else {
        // 以前的页面 1220
        document.getElementsByTagName("body").item(0).style.backgroundColor =
          "#ffffff";
        this.bodyWidth = false;
        // this.paddingWidthNum = 0;
      }
    },
  },

  // watch: {
  //   errApi: {
  //     deep: true, //深度监听设置为 true
  //     handler: function() {
  //       //// console.log(this.errApi)
  //       if (this.errApi.type == true) {
  //         this.$message({
  //           message: this.errApi.text,
  //           type: "error",
  //           duration: 1000
  //         });
  //       }
  //     }
  //   },

  // }
};
</script>

<style lang="scss">
@font-face {
  font-family: "TimesNewRoman";
  src: url("https://static.wtjy.com/webFont/NewRoman.ttf");
}
@font-face {
  font-family: "楷体";
  src: url("https://static.wtjy.com/webFont/SIMKAI.TTF");
}
@keyframes move {
  from {
    background-position: 0;
  }

  to {
    background-position: 3000px;
  }
}

.el-progress-animation {
  // margin-top: 24px;
  display: flex;

  .el-progress__text {
    margin-left: 70px;
  }

  .el-progress-bar__inner {
    animation: move 60s infinite linear;
    // background-color: #337ab7;
    background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 96px 96px;
  }

  .el-progress-bar__outer {
    height: 12px !important;
  }
}

.version-updating {
  .massage {
    max-height: 134px;
  }

  .el-dialog__header {
    background-image: url("~@/assets/header/headerShow.png");
    background-size: 100% 100%;
    height: 80px;
    line-height: 80px;
    padding: 0;
    padding-left: 24px;
  }

  .el-dialog__footer {
    text-align: center;
  }

  //
}

#app .el-radio__input {
  transform: rotate(-45deg);
}

#app .el-radio__input.is-checked .el-radio__inner::after {
  content: "";
  width: 70%;
  height: 40%;
  border: 2px solid white;
  border-top: transparent;
  border-right: transparent;
  text-align: center;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  vertical-align: middle;
  transform: translate(-40%, -60%);
  -ms-transform: translate(-40%, -60%);
  -moz-transform: translate(-40%, -60%);
  -webkit-transform: translate(-40%, -60%);
  border-radius: 0px;
  background: none;
}

.el-input__icon:after {
  position: absolute;
}

#app {
  min-width: 1275px;

  .btn-all {
    .el-icon--right {
      margin-left: 5px;
    }

    .el-button-group {
      border-radius: 4px;

      .el-button--primary {
        background: $primary-color;
        border-color: $primary-color;
      }
    }
  }

  .el-dropdown .el-dropdown__caret-button {
    padding-left: 5px;
    padding-right: 5px;
  }
}

//重置了一些样式
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /* Firefox */
  -webkit-box-sizing: border-box;

  /* Safari */
  &::after,
  &::before {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    /* Firefox */
    -webkit-box-sizing: border-box;
    /* Safari */
  }
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

img {
  vertical-align: middle;
}
.jzjxImg,
.jzjxAnswerImg {
  vertical-align: top;
  max-width: 100% !important;
  height: auto !important;
}
p {
  margin-bottom: 10px;
}

html,
body {
  font-size: 14px;
  // font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  letter-spacing: 1px;
  // background-color: #f4f4f4;
  scroll-behavior: smooth;
}

body {
  // 设为0是为了防止页面左右横跳
  padding-right: 0 !important;

  // padding-left: calc(100vw - 100%);
  color: #333333;
  .el-message-box__header {
    border-bottom: 1px solid #ededef;
    padding: 18px 24px;
    .el-message-box__headerbtn {
      right: 24px;
    }
    .el-message-box__title {
      font-size: 16px;
    }
  }
  .el-message-box__content {
    padding: 18px 24px;
  }
  .el-tooltip__popper.is-dark {
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
  }

  .el-table .el-table__cell {
    padding: 6.5px 0;
    color: #606266;
  }

  // background-color: #f4f4f4;
  .el-select-dropdown {
    .el-select-dropdown__item.selected {
      color: $primary-color;
    }
  }

  .el-dropdown-menu {
    padding: 5px 0;
  }

  .el-dropdown-menu__item {
    line-height: 26px;
    padding: 0 14px;
  }

  .el-table-filter__list-item:hover {
    color: $primary-color;
  }

  .el-table-filter__list-item.is-active {
    color: #fff;
    background-color: $primary-color;
  }
  /*加粗*/
  .mathjye-bold {
    font-weight: 800;
  }

  /*下划线*/
  .mathjye-underline {
    padding-bottom: 0px;
    border-bottom: 1px solid #000;
  }

  /*删除线*/
  .mathjye-del {
    text-decoration: line-through;
  }

  /*斜体*/
  .mathjye-italic {
    font-style: italic;
  }

  /*点线*/
  .mathjye-underpline {
    border-bottom: 2px dotted #000;
    padding-bottom: 3px;
  }

  // 菁优网格式修改
  .MathJye {
    border: 0 none;
    direction: ltr;
    display: inline-block;
    float: none;
    font-family: "JyeooHai-letter-Italic", "JyeooHai-letter",
      "JyeooHai-Main-Regular", "Times New Roman", "宋体";
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 1px;
    line-height: normal;
    margin: 0;
    padding: 0;
    text-align: left;
    text-indent: 0;
    text-transform: none;
    white-space: nowrap;
    word-spacing: normal;
    word-wrap: normal;
    -webkit-text-size-adjust: none;
    vertical-align: middle;
  }

  .MathJye table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    text-align: center;
    vertical-align: middle;
    line-height: normal;
    font-size: inherit;
    *font-size: 100%;
    /* _font-size: 100%; */
    font-style: normal;
    font-weight: normal;
    border: 0;
    float: none;
    display: inline-block;
    *display: inline;
    zoom: 0;
  }

  .MathJye table td {
    padding: 0;
    font-size: inherit;
    line-height: normal;
    white-space: nowrap;
    border: 0 none;
    width: auto;
    /* _height: auto; */
  }

  .MathJye table th {
    border: none !important;
    /* _height: auto; */
  }

  .MathJye_mi {
    font-style: normal;
  }
}

ul {
  li {
    list-style: none;
  }
}

button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
}

.el-popup-parent--hidden {
  overflow: initial !important;
}

body {
  .el-dialog {
    border-radius: 4px;
  }

  .question-box-img-style {
    img {
      max-width: 100%;
      height: auto !important;
      max-width: 100% !important;
    }
  }

  .question-list img {
    max-width: 100% !important;
    height: auto !important;
  }

  .el-date-editor i {
    line-height: 24px;
  }

  .el-date-editor .el-range-separator {
    line-height: 24px;
  }

  .el-input--suffix .el-input__inner {
    height: 32px;
    line-height: 32px;
  }

  .el-select .el-input .el-select__caret {
    line-height: 32px;
    height: 32px;
  }

  .el-input__inner {
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
  }

  .el-button {
    padding: 8px 14px;
    img {
      vertical-align: bottom;
    }
    .on {
      display: none;
    }
    &:hover,
    &:active,
    &:focus {
      img {
        display: none;
      }
      .on {
        display: inline-block;
      }
    }
  }

  .el-input__icon {
    line-height: 32px;
  }
  .el-cascader {
    line-height: 32px;
  }

  .el-switch {
    .is-active {
      span {
        color: $primary-color;
      }
    }
  }

  .el-input-number {
    line-height: 30px;
  }

  .el-input.el-input__inner.is-focus {
    border-color: #dad9d9;
  }

  .el-input.el-input__inner.is-active {
    border-color: #dad9d9;
  }

  .el-select .el-input.is-focus .el-input__inner {
    border-color: #dad9d9;
  }
}

//重置弹窗 按钮
/* 可选：如果需要给页面添加一些额外样式 */
body .no-scroll {
  overflow: hidden !important;
}
body {
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-right: 0;

    .el-pager li.active {
      color: $primary-color;
    }

    .el-pager li:hover {
      color: $primary-color;
    }
  }

  .el-input {
    .el-icon-search {
      cursor: pointer;
    }
  }
  .el-input.text-nums {
    .el-input__inner {
      padding-right: 50px;
    }
  }
  .edit-scroll-style {
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    ///*<!--修改 滑块 -->*/
    // border: 1px solid #DEE1E7;
    &::-webkit-scrollbar-thumb {
      background-color: #dee1e7;
    }
  }

  .el-table .el-table__header th {
    background-color: #f2f5f8;
    color: #101011;
  }
  .edit-scroll-x-style {
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    ///*<!--修改 滑块 -->*/
    // border: 1px solid #DEE1E7;
    &::-webkit-scrollbar-thumb {
      background-color: #dee1e7;
    }
  }

  .el-table .el-table__header th {
    background-color: #f2f5f8;
    color: #101011;
  }

  // 606266
  .el-dialog__header {
    padding: 18px;
    border-bottom: 1px solid #ededef;
    height: auto;
    position: relative;
    .el-dialog__title {
      font-weight: bold;
      line-height: 18px;
      font-size: 18px;
    }

    .el-dialog__headerbtn {
      right: 18px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .el-dialog__body {
    padding: 18px 24px 0;
  }

  .el-dialog__footer {
    padding: 10px 24px 30px;
  }

  .el-button.el-button--text {
    padding: 0;
  }

  .el-button {
    border-radius: 4px;
  }
  .el-button--text {
    border-radius: 0;
  }

  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    color: $primary-color;
  }

  .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item:not(.is-disabled):hover {
    color: $primary-color;
  }
}

.v-modal {
  opacity: 1 !important;
  background: rgba($color: #000000, $alpha: 0.5) !important;
}
</style>
